html {
  --font-roboto: "Roboto", Arial, sans-serif;
  --font-yellowtail: "Yellowtail", cursive;
  --casuals-pink: #ff99cc;
  --casuals-pink-2: #a5105b;
  --mat-autocomplete-background-color: #221013;
  --mat-option-focus-state-layer-color: #a5105b;
}

h1, h2, h3 {
  color: var(--casuals-pink);
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.yellowtail-regular {
  font-family: "Yellowtail", cursive;
  font-weight: 400;
  font-style: normal;
}

.bg-casuals {
  background-color: #16130f;
}

.bg-casuals-header {
  background: #16130f url('/assets/images/bg-header.png') no-repeat top center;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background-color: #a5105b;
  color: white;
}
